import { fileAdapter } from '@/adapters/constructor/utils/file';

export default ({ items }) => {
  return {
    items: items?.map(({ data }) => ({
      id: data.id,
      title: data.title,
      showDate: data.files_show_date,
      files: data.files?.map(fileAdapter),
    })),
  };
};
